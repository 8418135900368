<template>
  <div class="articleCon">
    <div class="articleMenu">
      <div class="articleMenuList">
        <div class="listItem" :style="getListAct(menuIdx === index)" v-for="(item, index) in getConfig.articleTypeList" :key="index" @click="menuSel(index,item)">
          <h5 @click="toLink(item, 1)">{{ item.type }}</h5>
          <p v-for="(x, index) in item.children" :key="index" @click="toLink(x,2)" :class="getClass(x)">
            {{ x.type }}
          </p>
        </div>
      </div>
    </div>
    <div class="articleList">
      <div class="listCon">
        <div class="listItem" v-for="(item, index) in pageList" :key="index" @click="linkToArticle(item.link)">
          <img :src="item.cover || img">
          <div class="listCon">
            <p>{{ item.name }}</p>
            <span>{{ item.dec }}</span>
            <span>{{ getTime(item.createTime) }}</span>
          </div>
        </div>
      </div>
      <div class="pageCut">
        <span>共 {{ page.count }} 条数据</span>
        <Page @on-change="pageCut" :total="page.count" :page-size="page.size" />
      </div>
      <!--<p v-if="typeArticle.length === 0">暂无数据</p>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import img from  "../../../assets/img/site/artcle.png"
export default {
  data() {
    return {
      webSite: {},
      menuIdx: -1,
      typeArticle: [],
      articleList: [],
      pageList: [],
      img:img,
      page: {
        count:0,
        size: 8,
      }
    };
  },
  computed: {
    ...mapGetters(["getConfig"])
  },
  watch: {
    $route() {
      this.pageList = [];
      this.init();
    },
    getConfig() {
      this.pageList = [];
      this.init();
    }
  },
  created() {
    this.init();
  },
  methods: {
    getListAct(type) {
      if (type) {
        return {
          backgroundColor: this.getConfig.pageColor,
          color: "#fff"
        }
      }
    },
    getClass(item) {
      return this.$route.query.id === item.link ? "menuPAct" : "";
    },
    init() {
      let idx = 0;
      for (let x of this.getConfig.articleTypeList) {
        for (let i of x.children) {
          if (this.$route.query.id === i.link) {
            this.menuIdx = idx;
          }
        }
        if (this.$route.query.id === x.link) {
          this.menuIdx = idx;
        }
        idx += 1;
      }
      this.pageCut(1);
    },
    pageCut(pageIdx){
      let baseList = [];
      if (this.$route.query.id === "list") {
        baseList = this.getConfig.articleList;
      }else  {
        for (let item of this.getConfig.articleList) {
          if (item.type === this.$route.query.id) {
            baseList.push(item);
          }
        }
      }
      let page = baseList.length > this.page.size ? pageIdx : 1;
      let pageLent = page * this.page.size > baseList.length ? baseList.length : page * this.page.size;
      this.pageList = baseList.slice(page * this.page.size - this.page.size, pageLent);
      this.page.num  = page;
      this.page.count = baseList.length
    },
    menuSel(idx) {
      this.menuIdx = idx;
    },
    linkToArticle(id) {
      this.$router.push({ name: "article", query: { id: id } });
    },
    getTime(time) {
      if (!time) {
        return "";
      }
      let d = new Date(time);
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let date = d.getDate();
      // let hour = d.getHours();
      // let minute = d.getMinutes();
      // let second = d.getSeconds();
      return year + "/" + month + "/" + date;
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    toLink(item, type) {
      // if (type === 1 && item.children.length >= 1) {
      //   let arr = [];
      //   this.typeArticle = [];
      //   for (let i of item.children) {
      //     arr.push(i.link);
      //   }
      //   for (let item of this.getConfig.articleList) {
      //     for (let i of arr) {
      //       if (item.type === i) {
      //         this.typeArticle.push(item);
      //       }
      //     }
      //   }
      //   return false;
      // }
      this.$router.push({ name: "list", query: { id: item.link } });
    }
  }
};
</script>

<style lang="less">
@import "../../../assets/less/article";
</style>
